import React from "react";
import Helmet from "react-helmet";
import Layout from "../../layout";
import PostListing from "../../blogComponents/PostListing/PostListing";
import SEO from "../../blogComponents/SEO/SEO";
import config from "../../../data/SiteConfig";
import './index.css';

export default class Blog extends React.Component {
  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges;
    return (
      <Layout>
        <div className="index-container">
          <Helmet title={`${config.siteTitle} | Blog`} />
          <SEO />
          <PostListing postEdges={postEdges} />
        </div>
      </Layout>
    );
  }
}
